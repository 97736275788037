import { ApiService } from "./ApiService";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { User } from "../../models/api/User";
import { AccountOrganization } from "../../models/api/AccountOrganization";
import { TableFilters } from "../../models/api/TableFilters";
import { PagedResults } from "../../models/api/PagedResults";
import { ExportResults } from "../../models/api/ExportResults";
import { Promise } from "bluebird";

export interface UserRequestResponse {
  requiresReset: boolean;
  success: boolean;
  message?: string;
}

export interface SignUpRequest {
  email: string;
  organizationName: string;
  firstName: string;
  lastName: string;
  password: string;
  recievePromoEmails: boolean;
  countryCode: string;
  organizationId?: string;
}

export interface ResendRequest {
  email: string;
}

export interface ConfirmVerificationRequest {
  email: string;
}

export class UsersApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "users");

    this.list = this.list.bind(this);
    this.listAll = this.listAll.bind(this);
  }

  getById(userId: string) {
    return new Promise<User>((resolve, reject, cancel) => {
      this.get(`${userId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID ?? "",
            accountId: d.ACCOUNTID,
            email: d.EMAIL ?? "",
            name: this.calculateName(d.FIRSTNAME, d.LASTNAME),
            organization: d.ORGNAME ?? "",
            orgId: d.ORGID,
            lastLogin:
              d.LASTLOGIN != undefined && d.LASTLOGIN != ""
                ? moment.utc(d.LASTLOGIN).local()
                : undefined,
            createdAt:
              d.CREATEDAT != undefined && d.CREATEDAT != ""
                ? moment.utc(d.CREATEDAT).local()
                : undefined,
            firstName: d.FIRSTNAME,
            lastName: d.LASTNAME,
            phoneNumber: d.PHONENUMBER,
            accessGroupIds: d.ACCESSGROUPIDS,
            profileImagePath: d.PROFILEIMAGEPATH,
            roleName: d.ROLENAME,
            roleId: d.ROLEID,
          } as User);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getCurrentSessionUser() {
    return new Promise<User>((resolve, reject, cancel) => {
      this.get(`mySession`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID ?? "",
            email: d.EMAIL ?? "",
            name: this.calculateName(d.FIRSTNAME, d.LASTNAME),
            organization: d.ORGNAME ?? "",
            orgId: d.ORGID,
            lastLogin:
              d.LASTLOGIN != undefined && d.LASTLOGIN != ""
                ? moment.utc(d.LASTLOGIN).local()
                : undefined,
            createdAt:
              d.CREATEDAT != undefined && d.CREATEDAT != ""
                ? moment.utc(d.CREATEDAT).local()
                : undefined,
            roleName: d.ROLENAME,
            roleId: d.ROLEID,
            profileImagePath: d.PROFILEIMAGEPATH ? d.PROFILEIMAGEPATH : "",
          } as User);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<User>> {
    return new Promise<PagedResults<User>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const users = result.data.map(
            (d: any) =>
              ({
                id: d.ID ?? "",
                accountId: d.ACCOUNTID,
                email: d.EMAIL ?? "",
                name: d.FULLNAME,
                organization: d.ORGNAME ?? "",
                orgId: d.ORGID,
                lastLogin:
                  d.LASTLOGIN != undefined && d.LASTLOGIN != ""
                    ? moment.utc(d.LASTLOGIN).local()
                    : undefined,
                createdAt:
                  d.CREATEDAT != undefined && d.CREATEDAT != ""
                    ? moment.utc(d.CREATEDAT).local()
                    : undefined,
                roleName: d.ROLENAME,
                roleId: d.ROLEID,
              }) as User,
          );
          resolve({
            items: users,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getAccountOrganizationsForUser(userId: string): Promise<AccountOrganization[]> {
    return new Promise<AccountOrganization[]>((resolve, reject, cancel) => {
      this.get(`${userId}/organizations`, cancel).done((result) => {
        if (result.success) {
          const accountOrganizations = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                accountId: d.ACCOUNTID,
                orgId: d.ORGID,
                roleId: d.ROLEID,
                createdAt: moment.utc(d.CREATEDAT).local(),
                isDefault: d.ISDEFAULT,
                ownerCount: d.OWNERCOUNT,
              }) as AccountOrganization,
          );
          resolve(accountOrganizations);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAll(tableFilters?: TableFilters): Promise<PagedResults<User>> {
    return new Promise<PagedResults<User>>((resolve, reject, cancel) => {
      this.get(
        `all${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const users = result.data.map(
            (d: any) =>
              ({
                id: d.ID ?? "",
                email: d.EMAIL ?? "",
                name: d.FULLNAME,
                organization: d.ORGNAME ?? "",
                orgId: d.ORGID,
                linkedOrgIds: d.LINKEDORGIDS,
                lastLogin:
                  d.LASTLOGIN != undefined && d.LASTLOGIN != ""
                    ? moment.utc(d.LASTLOGIN).local()
                    : undefined,
                createdAt:
                  d.CREATEDAT != undefined && d.CREATEDAT != ""
                    ? moment.utc(d.CREATEDAT).local()
                    : undefined,
                roleName: d.ROLENAME,
                roleId: d.ROLEID,
              }) as User,
          );
          resolve({
            items: users,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  exportAll(tableFilters?: TableFilters): Promise<ExportResults> {
    return new Promise<ExportResults>((resolve, reject, cancel) => {
      this.get(
        `export${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve({
            reportId: result.data,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAllByOrg(orgId: string, tableFilters?: TableFilters): Promise<PagedResults<User>> {
    return new Promise<PagedResults<User>>((resolve, reject, cancel) => {
      this.get(
        `all/${orgId}${
          tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const users = result.data.map(
            (d: any) =>
              ({
                id: d.ID ?? "",
                accountId: d.ACCOUNTID,
                email: d.EMAIL ?? "",
                name: d.FULLNAME,
                organization: d.ORGNAME ?? "",
                orgId: d.ORGID,
                linkedOrgIds: d.LINKEDORGIDS,
                lastLogin:
                  d.LASTLOGIN != undefined && d.LASTLOGIN != ""
                    ? moment.utc(d.LASTLOGIN).local()
                    : undefined,
                createdAt:
                  d.CREATEDAT != undefined && d.CREATEDAT != ""
                    ? moment.utc(d.CREATEDAT).local()
                    : undefined,
                roleName: d.ROLENAME,
                roleId: d.ROLEID,
              }) as User,
          );
          resolve({
            items: users,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  exportAllByOrg(orgId: string, tableFilters?: TableFilters): Promise<ExportResults> {
    return new Promise<ExportResults>((resolve, reject, cancel) => {
      this.get(
        `export/${orgId}${
          tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve({
            reportId: result.data,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  resetRequired(email: string): Promise<UserRequestResponse> {
    return new Promise<UserRequestResponse>((resolve, reject, cancel) => {
      this.get(`resetRequired/?userEmail=${encodeURIComponent(email)}`, cancel).done((result) => {
        if (result.success || result.message === "User could not be found") {
          resolve(result);
        } else {
          reject(new Error(result.message ?? ""));
        }
      });
    });
  }

  confirmVerification(confirmVerificationRequest: ConfirmVerificationRequest): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post("signUp/confirmation", confirmVerificationRequest, cancel).done((result) => {
        if (result.success) {
          resolve(result);
        } else {
          reject(new Error(result.message ?? ""));
        }
      });
    });
  }

  signUp(signUpRequest: SignUpRequest): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post("signUp", signUpRequest, cancel).done((result) => {
        if (result.success) {
          resolve(result);
        } else {
          reject(result.message ?? "");
        }
      });
    });
  }

  resend(resendRequest: ResendRequest): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post("signup/resend/verification", resendRequest, cancel).done((result) => {
        if (result.success) {
          resolve(result);
        } else {
          reject(result.message ?? "");
        }
      });
    });
  }

  update(editUser: User): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      super.post(`${editUser.id}`, editUser, cancel).done((result) => {
        if (result.success) {
          resolve(result.userId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateOrganizations(userId: string, accountOrganizations: AccountOrganization[]): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      super
        .post(
          `${userId}/organizations`,
          {
            "accountOrganizationOrgIds[]": accountOrganizations.map((ao) => ao.orgId),
            "accountOrganizationRoleIds[]": accountOrganizations.map((ao) => ao.roleId),
            "accountOrganizationIsDefaults[]": accountOrganizations.map((ao) => ao.isDefault),
          },
          cancel,
        )
        .done((result) => {
          if (result.success) {
            resolve();
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        });
    });
  }

  updatePermissions(
    accountId: string,
    accessGroupsId: string[],
    roleId?: string,
  ): Promise<string | undefined> {
    return new Promise<string | undefined>((resolve, reject, cancel) => {
      super
        .post(
          `${accountId}/permissions`,
          { accessGroupsId: accessGroupsId, roleId: roleId },
          cancel,
        )
        .done((result) => {
          if (result.success) {
            resolve(result.token);
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        });
    });
  }

  updatePassword(userId: string, passwordData: any): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      super.post(`${userId}/password`, passwordData, cancel).done((result) => {
        if (result.success) {
          resolve(result.userId as string);
        } else {
          console.error(result.message);
          reject(result.message);
        }
      });
    });
  }

  uploadProfileImage(userId: string, imageData: any): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      super.post(`uploadProfileImage/${userId}`, imageData, cancel).done((result) => {
        if (result.success) {
          resolve(userId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  removeProfileImage(userId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`removeProfileImage/${userId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  inviteUser(email: string, roleId: string): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      super
        .post(
          `inviteUser`,
          {
            email: email.toLowerCase(),
            roleId: roleId,
          },
          cancel,
        )
        .done((result) => {
          if (result.success) {
            resolve(result.message as string);
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        });
    });
  }

  remove(userId: string, hardDelete?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${userId}?hardDelete=${hardDelete ?? false}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  calculateName(FIRSTNAME: string, LASTNAME: string) {
    if (!FIRSTNAME && !LASTNAME) {
      return "";
    } else if (!FIRSTNAME && LASTNAME) {
      return LASTNAME;
    } else if (FIRSTNAME && !LASTNAME) {
      return FIRSTNAME;
    } else {
      return FIRSTNAME + " " + LASTNAME;
    }
  }
}
