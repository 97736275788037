import { FC } from "react";
import { useAuth } from "../../contexts/auth/auth.context";
import PageLayout from "./components/PageLayout";
import { useLocation } from "react-router-dom";

type Props = Record<string, never>;

const ResetPasswordRequest: FC<Props> = () => {
  const auth = useAuth();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const emailAddress = params.get("email") || auth.emailAddress;

  return (
    <PageLayout tagLine={""}>
      <div className="login-box">
        <div className="card card-outline">
          <div className="card-body">
            <h4 className="text-center w-100 mb-3">Reset sent!</h4>
            <p className="text-center mb-3">
              If a valid account can be found for <strong>{emailAddress}</strong> you will be sent a
              password reset link.
            </p>

            <div className="sub-login-form text-center">
              <span>
                <a className="link" href="/go/home/login">
                  Back to Login
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ResetPasswordRequest;
