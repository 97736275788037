import { useState, type FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AuthenticationApiService } from "../../services/api/AuthenticationApiService";
import { useAuth } from "../../contexts/auth/auth.context";
import PageLayout from "./components/PageLayout";

type Props = Record<string, never>;

const ResetPasswordPrompt: FC<Props> = () => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [emailFromInput, setEmailFromInput] = useState("");

  const params = new URLSearchParams(location.search);
  const emailFromState = params.get("email") ?? auth.emailAddress;

  const forgotPassword = async () => {
    const service = new AuthenticationApiService();
    const email = emailFromState || emailFromInput;

    // Ignore any failures — the user may not exist
    await service.requestPasswordReset(email).catch(() => {});

    history.push(`/go/home/resetPasswordRequest?email=${encodeURIComponent(email)}`);
  };

  const emailPrompt = emailFromState ? (
    <p className="text-center mb-3">
      <strong>{emailFromState}</strong> will be sent a password reset link.
    </p>
  ) : (
    <div className="form-group mb-3">
      <label>Email</label>
      <input
        className="form-control"
        type="email"
        value={emailFromInput}
        onChange={(ev) => setEmailFromInput(ev.target.value)}
      />
    </div>
  );

  return (
    <PageLayout tagLine={""}>
      <div className="login-box">
        <div className="card card-outline">
          <div className="card-body">
            <h4 className="text-center w-100 mb-3">Reset your password?</h4>

            {emailPrompt}

            <div className="sub-login-form text-center">
              <span>
                <button
                  type="submit"
                  className="btn btn-fill ml-2"
                  onClick={() => forgotPassword()}
                >
                  Send Password Reset Link
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ResetPasswordPrompt;
